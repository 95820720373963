/** @jsx jsx */
import React from 'react'
import { graphql } from 'gatsby'
import { Styled, jsx } from 'theme-ui'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Layout from '../layout/layout'

import { Hero, Section, Button } from '../components'

class AboutIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')

    return (
      <Layout location={this.props.location}>
        <div>
          <Helmet title={`About | ${siteTitle}`} />
          <Hero
            title='About us'
            subtitle='Here at Smart Home and Cinema we take pride in offering bespoke luxury home cinemas as well as state of the art smart home solutions, such as full home automation, smart lighting, and CCTV and security systems. We do all of these whilst ensuring that you, the customer, have an exciting yet stress-free experience throughout the process from the initial design to the completion of your bespoke project with us.'
            size={[4, 6]}
            minHeight={[0, '40vh']}
          />

          <Section variant='white'>
            <div
              sx={{
                display: 'grid',
                gridTemplateColumns: [
                  'repeat(auto-fill, minmax(250px, 1fr))',
                  'repeat(auto-fill, minmax(500px, 1fr))',
                ],
                gridAutoRows: '1fr',
                gridGap: 5,
                alignItems: 'stretch',
                mb: [3, 6],
              }}
            >
              <div>
                <iframe
                  src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2424.009643644524!2d-1.8895878841900284!3d52.58751887982705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4870a409e746b379%3A0x1082ea85abdd8676!2s1DP%2C%20128%20Hardwick%20Rd%2C%20Streetly%2C%20Sutton%20Coldfield%20B74%203DP!5e0!3m2!1sen!2suk!4v1594418360122!5m2!1sen!2suk'
                  width='100%'
                  height='500px'
                  frameborder='0'
                  allowfullscreen=''
                  aria-hidden='false'
                  tabindex='0'
                  title='Google Map Embed'
                ></iframe>
              </div>
              <div
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <Styled.h2>Our Showroom</Styled.h2>
                <p>
                  Our showroom is located at 128-138 Hardwick Road, Sutton Coldfield, B74 3DP above
                  Broadway Kitchens. Viewings can be arranged by appointment, so please contact us
                  using one of the methods below and one of our experts will get back to you.
                </p>
                <Button to='/contact'>Contact Us</Button>
              </div>
            </div>
            <div sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}></div>
          </Section>
        </div>
      </Layout>
    )
  }
}

export default AboutIndex

export const pageQuery = graphql`
  query AboutIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
